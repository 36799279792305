const initialState = {
  jobOpportunitiesListData: {},
  jobOpportunityDetailsInfo: {},
  jobApplicantsAppliedList: {},
  jobApplicantList: {},
  jobApplicantData: {},
};

const jobOpportunity = (state = initialState, action) => {
  console.log("action type", action.payload);
  switch (action.type) {
    case "GET_JOB_OPPORTUNITIES":
      return {
        ...state,
        jobOpportunitiesListData: {
          ...action.payload,
        },
      };
    // case "JOB_OPPORTUNITY_DETAILS":
    //   return {
    //     ...state,
    //     jobOpportunityDetailsInfo: {
    //       ...action.payload,
    //     },
    //   };
    case "JOB_APPLICANTS_APPLIEDLIST":
      return {
        ...state,
        jobApplicantsAppliedList: {
          ...action.payload,
        },
      };
    case "JOB_APPLICANTS_LIST":
      console.log("JOB_APPLICANTS_LIST reducer", action.payload);
      return {
        ...state,
        jobApplicantList: {
          ...action.payload,
        },
      };
    case "JOB_APPLICANTS_DATA": {
      return {
        ...state,
        jobApplicantData: {
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
export default jobOpportunity;
