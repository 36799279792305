// **  Initial State
const initialState = {
  data: [],
};

const jobTitleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "JOB_TITLES":
      return {
        ...state,
        data: action.payload?.results,
      };
    default:
      return state;
  }
};

export default jobTitleReducer;
