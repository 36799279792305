import { printConsole } from "../../../views/development/print";
// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
const config = useJwt.jwtConfig;
// **  Initial State
const initialState = {
  userData: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      printConsole("state authreduxer", state, action);
      return {
        ...state,
        userData: action.payload,
        isAuthenticated: true,
        [config.storageTokenKeyName]: action.payload.tokens.access.token,
        [config.storageRefreshTokenKeyName]:
          action.payload.tokens.refresh.token,
      };
    case "LOGOUT":
      const obj = { ...action };
      delete obj?.accessToken;
      delete obj?.refreshToken;
      return { userData: {}, isAuthenticated: false };

    case "ERROR_REFRESH_TOKEN":
      let objAuth = { ...action };
      delete objAuth?.accessToken;
      delete objAuth?.refreshToken;
      return { unAuth: true, userData: {}, isAuthenticated: false };
    default:
      return state;
  }
};

export default authReducer;
