// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import jobOpportunity from "./jobOpportunity";
import educations from "./educations";
import businessOpportunity from "./businessOpportunity";
import jobTitles from "./jobTitles";
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  jobOpportunity,
  businessOpportunity,
  educations,
  jobTitles,
});

export default rootReducer;
