const initialState = {
  businessOpportunityList: {},
  businessOpportunityAppliedList: {},
};

const businessOpportunity = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BUSINESS_OPPORTUNITIES":
      return {
        businessOpportunityList: {
          ...action.payload,
        },
      };

    case "BUSINESS_OPPORTUNITY_DETAILS":
      return {
        ...state,
        businessOpportunityDetailsInfo: {
          ...action.payload,
        },
      };
    case "BUSINESS_OPPORTUNITY_APPLIEDLIST":
      return {
        ...state,
        businessOpportunityAppliedList: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default businessOpportunity;
